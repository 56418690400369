import React from "react";
import ReactDOM from "react-dom";
import "assets/plugins/nucleo/css/nucleo.css";
import "assets/scss/argon-dashboard-react.scss";
import App from "App";
import { store } from "store";
import { Provider } from "react-redux";
import Toast from "components/ToastNotification";
import NotificationApp from 'components/NotificationModal/Notification';
import { enableMapSet } from "immer"

enableMapSet()

window.process = {};
const isAppleDevice = /iPhone|iPad|iPod|Macintosh/.test(navigator.userAgent);

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Toast />
      <App />
    </React.StrictMode>
    {!isAppleDevice && <NotificationApp />}
  </Provider>,
  document.getElementById("root")
);
