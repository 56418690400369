import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPushNotificationList } from "store/reducers/common.slice";
import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';  // Import messaging from firebase compat
import 'firebase/compat/database';
import 'firebase/compat/firestore';

// Your Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDnU_kpnlVNXFFF9D1JpvPBjS4OwDXo80I",
  authDomain: "golden-money-5b3f7.firebaseapp.com",
  databaseURL: "https://golden-money-5b3f7-default-rtdb.firebaseio.com",
  projectId: "golden-money-5b3f7",
  storageBucket: "golden-money-5b3f7.appspot.com",
  messagingSenderId: "1052396250571",
  appId: "1:1052396250571:web:67bb2ff6ed87b43685824e",
  measurementId: "G-VL2MM4Q6LJ"
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// Get a messaging instance
const messaging = firebase.messaging();

const NotificationApp = () => {
  const [notifications, setNotifications] = useState([]);
  const dispatch = useDispatch(); 
  useEffect(() => {
    // Request permission to show notifications
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        let savedToken = localStorage.getItem('fcmToken');
         if (!savedToken) {
            messaging.getToken({ vapidKey: 'BDwMFKNwkhfmDxPCnJAiF7OdLWOPaQBV3vYWBxKDOkUeQqVe8gaiJXMFzATQ_mzhKeuFJnT0kUQKzYX8i0-M1c0' })
              .then((currentToken) => {
                if (currentToken) {
                  localStorage.setItem('fcmToken', currentToken);
                } else {
                  console.log('No registration token available. Request permission to generate one.');
                }
              }).catch((err) => {
                console.log('An error occurred while retrieving token. ', err);
              });
          }
      } else {
        console.log('Unable to get permission to notify.');
      }
    });

    // Handle foreground messages
    messaging.onMessage((payload) => {
      const newNotification = {
        title: payload.notification.title,
        message: payload.notification.body,
      };
      setNotifications((prev) => [...prev, newNotification]);
      dispatch(getPushNotificationList())
    });
  }, [dispatch]);
  useEffect(() => {
    // Close each notification after 10 seconds
    const timers = notifications.map((_, index) => {
      return setTimeout(() => handleClose(index), 10000); // Close after 10 seconds
    });

    // Clear timeouts on unmount or when notifications change
    return () => {
      timers.forEach((timer) => clearTimeout(timer));
    };
  }, [notifications]);
  const handleClose = (index) => {
    setNotifications((prev) => prev.filter((_, i) => i !== index)); // Remove notification
  };
  return (
  <div className="notification-container" style={{ position: 'fixed', bottom: '20px', right: '20px', zIndex: 1000 }}>
    {notifications.map((notif, index) => (
      <div
        key={index}
        className="notification"
        style={{
          background: '#444',
          color: '#fff',
          padding: '15px',
          margin: '10px 0',
          borderRadius: '10px',
          boxShadow: '0 4px 15px rgba(0, 0, 0, 0.3)',
          animation: 'slideIn 0.5s ease, fadeOut 0.5s ease 4s forwards', // Add fade out animation
          transition: 'opacity 0.5s ease',
          opacity: 1,
          position: 'relative',
        }}
      >
        <strong style={{ display: 'block', marginBottom: '5px', fontSize: '16px', fontWeight: '600' }}>{notif.title}</strong>
        <p style={{ margin: '0', fontSize: '14px', lineHeight: '1.4' }}>{notif.message}</p>
        <span
          onClick={() => handleClose(index)}
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            cursor: 'pointer',
            fontSize: '18px',
            color: '#f00',
            transition: 'transform 0.3s', // Add hover effect
          }}
          className="fas fa-times"
        ></span>
      </div>
    ))}
  </div>
);

};

export default NotificationApp;
