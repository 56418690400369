import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { showMessage } from "store/reducers/common.slice";
import axios from "axios";
import _ from "lodash";
import { getSessionData } from "utils/common";
import { setMarketWatchList } from "./marketWatch.slice";
import { getSessionDataOnlyRead } from "utils/common";
const initialState = {
  isLoading: true,
  isLogOut: false,
  rulesAccepted: true,
  isShowChangePassword: false,
  isUserAuthenticated: false,
  isResetPassword: false,
  loginLoading: false,
  userIp: "",
  isVerifyPassword: false,
  userRole: "",
  isMasterRole: "",
  isUserLogin: false,
  device: "",
};

const getIpAddress = () => {
  return new Promise((resolve, reject) => {
    fetch("https://api.ipify.org/?format=json")
      .then((data) => data.json())
      .then((data) => {
        if (Object.keys(data).length > 0 && data.ip) {
          resolve(data.ip);
        } else {
          reject(data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const loginAction = createAsyncThunk(
  "auth/login",
  (dataProp, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let dataF = _.omit(dataProp, ["tc"]);
      dataF.deviceType = 1;
      dataF.sourceDevice = navigator.userAgent;
      let { username, password, isRemember, IsUserLogin } = dataF;
      getIpAddress()
        .then((ipData) => {
          let userIpData = ipData;
          dataF.userIp = userIpData;
          dataF.projectType = 3;
          dataF.totp = dataF.totp?dataF.totp:0;
          let fcmToken=localStorage.getItem('fcmToken');
          if(fcmToken){
             dataF.fcmToken=fcmToken;
          }
          if (isRemember) {
            window.document.cookie = `trade=${username},${password}; Path=/;`;
          } else {
            window.document.cookie = "trade=; Path=/;";
          }
          axios
            .post("user/login", dataF)
            .then(({ data }) => {
              if (data.status === 200) {
                sessionStorage.setItem("loggedInUrl", window.location.pathname);
                data.data.userIpFinal = data.data.ip;
                data.data.isRemember = isRemember;
                //data.data.password = password;
                if (IsUserLogin) {
                  data.data.IsUserLogin = IsUserLogin;
                }
                dispatch(setIsUserLogin(IsUserLogin));
                let finalData = data.data;
                if (data.data.roles[0] === 'User' && password === 'Aabb@1122') {
                  dispatch(setIsShowChangePassword(true))
                  finalData.isShowChangePassword = true
                }
                sessionStorage.setItem(
                  "TradeSession",
                  window.btoa(JSON.stringify(finalData))
                );
                if (data.data.roles[0] === 'User' && !data?.data?.rulesAccepted) {
                  dispatch(setRulesAccepted(data?.data?.rulesAccepted))
                }
                axios.defaults.headers.common[
                  "Authorization"
                ] = `Bearer ${finalData.tokens.accessToken}`;
                dispatch(
                  showMessage({ message: data.message, varient: "success" })
                );
                dispatch(setUserLogInIp(userIpData));
                resolve({
                  data,
                  userIp: userIpData,
                });
              } else {
                dispatch(showMessage({ message: data.message }));
                reject({ message: data.message });
              }
            })
            .catch((error) => {
              if (error.response) {
                dispatch(showMessage({ message: error.response.data.message }));
              } else if (error.request) {
                dispatch(showMessage({ message: error.request.message }));
              } else {
                dispatch(showMessage({ message: error.message }));
              }
              reject(error);
            });
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const logOut = createAsyncThunk(
  "auth/logout",
  (data, { getState, dispatch }) => {
    return new Promise((resolve, reject) => {
      //_.omit(data, ['totp', 'tc'])
      let { userId, userIpFinal, isRemember, IsUserLogin } = getSessionData();
      data.deviceType = 1;
      data.id = userId;
      data.sourceDevice = navigator.userAgent;
      data.userIp = userIpFinal;
      axios
        .post("user/log-out", data)
        .then(({ data }) => {
          if (data.status == 200) {
            if (!isRemember) {
              window.document.cookie = "trade=; Path=/;";
            }
            dispatch(setIsUserLogin(IsUserLogin));
            sessionStorage.removeItem("TradeSession");
            sessionStorage.removeItem("DeviceType");
            sessionStorage.removeItem("TradeAuthoritySession");
            axios.defaults.headers.common["Authorization"] = "";
            localStorage.removeItem('fcmToken'); 
            dispatch(
              showMessage({ message: data.message, varient: "success" })
            );
            dispatch(setMarketWatchList());
            resolve(data);
          } else {
            sessionStorage.removeItem("TradeSession");
            sessionStorage.removeItem("DeviceType");
            sessionStorage.removeItem("TradeAuthoritySession");
            axios.defaults.headers.common["Authorization"] = "";
            localStorage.removeItem('fcmToken'); 
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);

export const resetPassword = createAsyncThunk(
  "auth/reset-password",
  (data, { getState, dispatch }) => {
    return new Promise((resolve, reject) => {
      const { userName } = getSessionData();
      let finalObject = {};
      finalObject = data;
      finalObject.userName = userName;
      axios
        .post("user/reset-password", data)
        .then((res) => {
          if (res.data?.status === 200) {
            let sessionData = getSessionDataOnlyRead();
            sessionData.password = data.password
            sessionStorage.setItem(
              "TradeSession",
              window.btoa(JSON.stringify(sessionData))
            );
            dispatch(showMessage({ message: res?.data?.message, varient: "success" }));
            resolve(res);
          } else {
            dispatch(showMessage({ message: res.data?.message }));
            reject({ message: res.data?.message });
          }
        })
        .catch((errors) => {
          if (errors.response) {
            dispatch(showMessage({ message: errors.response.data.title }));
          } else if (errors.request) {
            dispatch(showMessage({ message: errors.request.title }));
          } else {
            dispatch(showMessage({ message: errors.message }));
          }
          reject(errors);
        });
    });
  }
);
export const checkVerifyPassword = createAsyncThunk(
  "auth/verify-password",
  (password, { getState, dispatch }) => {
    return new Promise((resolve, reject) => {
      const { userName } = getSessionData();
      const object = { userName, password }
      axios
        .post("user/password-verify", object)
        .then(({ data }) => {
          if (data?.status === 200) {
            resolve({ isVerifyPassword: true })
          } else {
            dispatch(showMessage({ message: data.message }));
          }
        })
        .catch((errors) => {
          if (errors.response) {
            dispatch(showMessage({ message: errors.response.data.title }));
          } else if (errors.request) {
            dispatch(showMessage({ message: errors.request.title }));
          } else {
            dispatch(showMessage({ message: errors.message }));
          }
          reject(errors);
        });
    });
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserLogInIp: (state, action) => {
      state.userIp = action.payload;
    },
    decrement: (state) => {
      state.value -= 1;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
    setUserAuthenticated: (state, action) => {
      state.isUserAuthenticated = action.payload;
    },
    setIsResetPassword: (state, action) => {
      state.isResetPassword = action.payload;
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload;
    },
    setIsMasterRole: (state, action) => {
      state.isMasterRole = action.payload;
    },
    setIsLogout: (state, action) => {
      state.isLogOut = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setIsUserLogin: (state, action) => {
      state.isUserLogin = action.payload;
    },
    setUserDevice: (state, action) => {
      state.device = action.payload;
      if (action.payload) {
        sessionStorage.setItem(
          "DeviceType",
          window.btoa(JSON.stringify(action.payload))
        );
      }
    },
    setRulesAccepted: (state, action) => {
      state.rulesAccepted = action.payload;
    },
    setIsShowChangePassword: (state, action) => {
      state.isShowChangePassword = action.payload;
    },
  },
  extraReducers: {
    [loginAction.pending]: (state) => {
      state.isUserAuthenticated = false;
      state.loginLoading = true;
    },
    [loginAction.rejected]: (state) => {
      state.isUserAuthenticated = false;
      state.loginLoading = false;
    },
    [loginAction.fulfilled]: (state, action) => {
      if (action.payload.data.status == 200) {
        state.userIp = action.payload.userIp;
        state.isUserAuthenticated = true;
        state.loginLoading = false;
      }
    },
    [logOut.pending]: (state) => {
      state.isUserAuthenticated = false;
    },
    [logOut.rejected]: (state) => {
      state.isUserAuthenticated = false;
      state.loginLoading = false;
      state.isLogOut = true;
    },
    [logOut.fulfilled]: (state, action) => {
      state.userIp = "";
      state.isUserAuthenticated = false;
      state.isLogOut = true;
      state.loginLoading = false;
    },
    [resetPassword.pending]: (state) => {
      state.isResetPassword = false;
      state.loginLoading = true;
    },
    [resetPassword.rejected]: (state) => {
      state.isResetPassword = false;
      state.loginLoading = false;
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.loginLoading = false;
      state.isResetPassword = true;
    },
  },
});

export const {
  decrement,
  setUserLogInIp,
  incrementByAmount,
  setUserAuthenticated,
  setUserRole,
  setIsMasterRole,
  setIsLogout,
  setIsLoading,
  setIsUserLogin,
  setUserDevice,
  setRulesAccepted,
  setIsShowChangePassword,
  setIsResetPassword
} = authSlice.actions;

export default authSlice.reducer;
