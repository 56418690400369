import React, { useState,useEffect } from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getPushNotificationList,markNotification,clearNotification} from "store/reducers/common.slice";
const NotificationBell = () => {
  const { pushnotificationList } = useSelector(
    ({ common }) => common
  );
  const dispatch = useDispatch(); 
  useEffect(() => {
    dispatch(getPushNotificationList());
  }, [dispatch]);
  const unreadCount = pushnotificationList.filter(notification => !notification.isReadable).length;
  const markAllAsRead = () => {
    dispatch(markNotification()).then(() => {
      dispatch(getPushNotificationList());
    });
  };
  const clearNotifications = () => {
    dispatch(clearNotification()).then(() => {
      dispatch(getPushNotificationList());
    });
  };
  return (
    <UncontrolledDropdown nav>
      <DropdownToggle nav className="nav-link-icon position-relative tv_dropdown">
        <span className="material-symbols-outlined" style={{ fontSize: "2rem", position: "relative" }}>
          notifications
          {unreadCount > 0 && (
            <span 
              className="badge bg-danger text-white rounded-circle position-absolute"
              style={{
                fontSize: "10px",
                minWidth: unreadCount > 9 ? "1.8rem" : "1.5rem", // Increase width for two digits
                height: "1.5rem",    
                lineHeight: "1.5rem", 
                textAlign: "center",
                top: "-0.4rem",   // Align the badge at the top of the bell icon
                right: "-0.7rem", // Move it more to the left
                display: "flex",  // Ensure text is centered
                justifyContent: "center",
                alignItems: "center",
                padding: "0.3em"
              }}
            >
              {unreadCount}
            </span>
          )}
        </span>
      </DropdownToggle>

      <DropdownMenu 
        style={{
          maxHeight: '300px', // Set the maximum height
          overflowY: 'auto'   // Enable vertical scrolling
        }}
      >
        <DropdownItem header>Notifications</DropdownItem>
        {pushnotificationList.length === 0 ? (
          <DropdownItem disabled>No new notifications</DropdownItem>
        ) : (
          <>
            {pushnotificationList.map(notification => (
              <DropdownItem key={notification.id} className={notification.isReadable ? 'text-muted' : ''}>
                {notification.notificationTitle} - {notification.notificationMessage}
              </DropdownItem>
            ))}
            <DropdownItem divider />
            <DropdownItem onClick={markAllAsRead}>Mark all as read</DropdownItem>
            <DropdownItem onClick={clearNotifications}>Clear all notifications</DropdownItem>
          </>
        )}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default NotificationBell;
