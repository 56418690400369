import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { checkIsEmpty, getSessionData } from "utils/common";

const initialState = {
  accountTypeOptionList: [],
  segmentsList: [],
  symbolList: [],
  scriptList: [],
  messageOptions: "success added",
  message: "",
  showMessage: false,
  varient: "success",
  openDeleteConfirmDialog: false,
  agentORSubBrokerList: [],
  expiryDatesBySymbolList: [],
  accountOptionList: [],
  partnerList: [],
  cashbookList: [],
  systemUserList: [],
  isNavbarHidden: false,
  editDataObjectCommon: {},
  commonAllUsers: [],
  focusState: false,
  rightsSessionList: [],
  notificationList: [],
  pushnotificationList: [],
  segmentListLoading: false,
  accountCommonListLoading: false,
  symbolListLoading: false,
  privacyPolicyData: [],
  symbolListWithExpiry: [],
  isAcceptedRules: false,
  isAcceptedRulesLoader: false
};

export const getSegmentKeyWorkByValue = (selectObject) => {
  if (selectObject.value === 1) {
    return "fonse";
  } else if (selectObject.value === 2) {
    return "mcx";
  } else if (selectObject.value === 3) {
    return "fonse";
  } else if (selectObject.value === 4) {
    return "nsecd";
  }
};

export const isAllowShow = createAsyncThunk(
  "/isAllowShow/bool",
  (routeName, { dispatch, getState }) => {
    return new Promise((resolve, reject) => {
      let { rightsSessionList } = getState().common;
      let { roles } = getSessionData();
      if (roles[0] === "Super Admin") {
        resolve(true);
      } else {
        let arr =
          rightsSessionList &&
          rightsSessionList.filter((a) => a.routeName === routeName);
        if (arr.length > 0) {
          resolve(arr[0].isViewAllow);
        } else {
          resolve(false);
        }
      }
    });
  }
);

export const getSegmentOptionList = createAsyncThunk(
  "/segment/get",
  (data, { getState }) => {
    return new Promise((resolve, reject) => {
      const { segmentsList = [] } = getState().common;
      if (segmentsList?.length > 0) {
        resolve(segmentsList);
        return;
      }
      axios
        .get("master/segment/get-segments")
        .then((resData) => {
          if (resData.status === 200) {
            let { data } = resData.data;
            const newDataArray =
              data &&
              data.map((item, i) => {
                return { label: item.name, value: item.id };
              });
            resolve(newDataArray);
          } else {
            reject(resData);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);
export const getSegmentOptionListByUser = createAsyncThunk(
  "/segment-by-user/get",
  (userId, { getState }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`master/segment/get-segments-by-user-id/${userId}`)
        .then((resData) => {
          if (resData.status === 200) {
            let { data } = resData.data;
            const newDataArray =
              data &&
              data.map((item, i) => {
                return { label: item.name, value: item.id };
              });
            resolve(newDataArray);
          } else {
            reject(resData);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const getScriptList = createAsyncThunk(
  "/script/get",
  ({ id }, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `settings/symbol-expiry/get-symbol-expiry-symbols-by-segment/${id}`
        )
        .then(({ data }) => {
          let { status, data: dataArray } = data;
          if (status === 200 && dataArray && dataArray.length > 0) {
            resolve(dataArray);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);
export const getPrivacyPolicyData = createAsyncThunk(
  "get-privacy-policy",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .get("common/get-privacy-policy-data")
        .then(({ data }) => {
          let { status, data: dataArray } = data;
          if (status === 200 && dataArray && dataArray.length > 0) {
            resolve(dataArray);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

let ajaxSymbolListRequest = null;
export const getSymbolList = createAsyncThunk(
  "/symbol/get",
  ({ id, optType }, { dispatch }) => {
    return new Promise((resolve, reject) => {
      if (ajaxSymbolListRequest) ajaxSymbolListRequest.cancel("CANCEL");
      ajaxSymbolListRequest = axios.CancelToken.source();

      axios
        .get(
          `market-watch/get-symbols-by-segment/${id}${optType ? `?optType=${optType}` : ""
          }
        `,
          { cancelToken: ajaxSymbolListRequest.token }
        )
        .then(({ data }) => {
          let { status, data: dataArray } = data;
          if (status === 200 && dataArray && dataArray.length > 0) {
            resolve(dataArray);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);
export const getSymbolListWithExpiry = createAsyncThunk(
  "/symbol-with-exp/get",
  (segmentId, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`script/bhav-copy/Get-Script-Data-By-SegmentId/${segmentId}`)
        .then(({ data }) => {
          let { status, data: dataArray } = data;
          if (status === 200 && dataArray && dataArray.length > 0) {
            resolve(dataArray);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);
export const isRulesAccepted = createAsyncThunk(
  "/rules-accepted",
  (props) => {
    return new Promise((resolve, reject) => {
      axios
        .get('common/is-rules-accepted')
        .then(({ data }) => {
          if (data?.status === 200) {
            resolve(true);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);
export const getSymbolListForNse = createAsyncThunk(
  "/symbol/get-opt",
  ({ segmentId, IsQtySet }, { dispatch }) => {
    return new Promise((resolve, reject) => {
      if (ajaxSymbolListRequest) ajaxSymbolListRequest.cancel("CANCEL");
      ajaxSymbolListRequest = axios.CancelToken.source();
      axios
        .get(`market-watch/get-MW-symbols-for-nse/${segmentId}${IsQtySet ? `?IsQtySet=${IsQtySet}` : ""
          }`)
        .then(({ data }) => {
          let { status, data: dataArray } = data;
          if (status === 200 && dataArray && dataArray.length > 0) {
            resolve(dataArray);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const getAgentSubBroker = createAsyncThunk("/agentSubBroker", () => {
  return new Promise((resolve, reject) => {
    let { userId } = getSessionData();

    axios
      .get(`common/get-sub-brokers/${userId}`)
      .then(({ data }) => {
        let { status, data: dataArray } = data;
        if (status === 200 && dataArray && dataArray.length > 0) {
          resolve(dataArray);
        } else {
          reject(data);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
});

export const getTokenScriptWiseLiveRateList = createAsyncThunk(
  "/script/get",
  ({ id }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `settings/symbol-expiry/get-symbol-expiry-symbols-by-segment/${id}`
        )
        .then(({ data }) => {
          let { status, data: dataArray } = data;
          if (status === 200 && dataArray && dataArray.length > 0) {
            resolve(dataArray);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const getAccountCommonList = createAsyncThunk(
  "/account/common",
  ({ userId, roleName }) => {
    return new Promise((resolve, reject) => {
      userId
        ? axios
          .get(
            `common/get-account-names-by-authorityid-and-rolename/${userId}/${roleName}`
          )
          .then(({ data }) => {
            if (data.status === 200) {
              let { data: dataArray } = data;
              if (dataArray && dataArray.length > 0) {
                resolve({ dataArray, roleName });
              } else {
                reject(data);
              }
            } else {
              reject(data);
            }
          })
          .catch((error) => {
            reject(error);
          })
        : axios
          .get(`common/get-account-names-by-rolename/${roleName}`)
          .then(({ data }) => {
            if (data.status === 200) {
              let { data: dataArray } = data;
              resolve({ dataArray, roleName });
            } else {
              reject(data);
            }
          })
          .catch((error) => {
            reject(error);
          });
    });
  }
);

export const getAccountTypeOption = createAsyncThunk("/accountType", () => {
  return new Promise((resolve, reject) => {
    axios
      .get("common/get-account-types")
      .then((resData) => {
        if (resData.status === 200) {
          let { data } = resData.data;
          const newDataArray =
            data &&
            data.map((item, i) => {
              return { label: item.name, value: item.id };
            });
          resolve(newDataArray);
        } else {
          reject(resData);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
});
export const getNotificationList = createAsyncThunk("/getNotification", () => {
  return new Promise((resolve, reject) => {
    axios
      .get("common/get-today-notification-list")
      .then((resData) => {
        if (resData.status === 200) {
          let { data } = resData.data;
          if (data.length > 0) {
            data = data.reverse();
          }
          resolve(data);
        } else {
          reject(resData);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
});
export const markNotification = createAsyncThunk("/markNotification", () => {
  return new Promise((resolve, reject) => {
    axios
      .get("common/notifications/mark-as-all-read")
      .then((resData) => {
        if (resData.status === 200) {
          let { data } = resData.data;
          resolve(data);
        } else {
          reject(resData);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
});
export const clearNotification = createAsyncThunk("/clearNotification", () => {
  return new Promise((resolve, reject) => {
    axios
      .get("common/notifications/clear-all-readed-notifications")
      .then((resData) => {
        if (resData.status === 200) {
          let { data } = resData.data;
          resolve(data);
        } else {
          reject(resData);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
});
export const getPushNotificationList = createAsyncThunk("/pushgetNotification", () => {
  return new Promise((resolve, reject) => {
    let { userId } = getSessionData();
    axios
      .get(`common/notifications/get-push-notifications-details/${userId}/1/50`)
      .then((resData) => {
        if (resData.status === 200) {
          let { data } = resData.data;
          if (data.records.length > 0) {
            data = data.records.reverse();
          }
          resolve(data);
        } else {
          reject(resData);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
});

export const getSymbolExpiryFromSymbol = createAsyncThunk(
  "/setting/symbol expiry/get",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { id } = data;
      axios
        .get(`settings/symbol-expiry/get-expiry-dates-by-symbol/${id}`)
        .then(({ data }) => {
          let { status, data: dataArray } = data;
          if (status === 200 && dataArray && dataArray.length > 0) {
            resolve(dataArray);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const getEditDataObjectCommon = createAsyncThunk(
  "/edit/data-object/get",
  (url, { dispatch }) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${url}`)
        .then((res) => {
          let { status, data } = res.data;
          if (status === 200 && !checkIsEmpty(data)) {
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);

export const getRightsForSession = createAsyncThunk(
  "admin/getRightsForSession/list",
  (id) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`settings/authorityRights/get-authorityRights-by-userid/${id}`)
        .then((resData) => {
          if (resData.status === 200) {
            let { data } = resData.data;
            sessionStorage.setItem(
              "TradeAuthoritySession",
              JSON.stringify(data)
            );
            resolve(data);
          } else {
            reject(resData);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);
export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    showMessage: (state, action) => {
      state.message = action.payload.message;
      state.varient = action.payload.varient;
      state.showMessage = true;
    },
    hideMessage: (state, action) => {
      state.showMessage = false;
    },
    setOpenDeleteConfirmDialog: (state, action) => {
      state.openDeleteConfirmDialog = action.payload;
    },
    setSymbolList: (state, action) => {
      state.symbolList = action.payload;
    },
    setAgentORSubBrokerList: (state, action) => {
      state.agentORSubBrokerList = action.payload;
    },
    setAccountOptionList: (state, action) => {
      state.accountOptionList = action.payload;
    },
    setIsNavbarHidden: (state, action) => {
      state.isNavbarHidden = action.payload;
    },
    setEditDataObjectCommon: (state, action) => {
      state.editDataObjectCommon = action.payload;
    },
    setFocusState: (state, action) => {
      state.focusState = action.payload;
    },
    setCommonAllUsers: (state, action) => {
      state.commonAllUsers = action.payload;
    },
    setSymbolListWithExpiry: (state, action) => {
      state.symbolListWithExpiry = action.payload;
    },
    setIsAcceptedRules: (state, action) => {
      state.isAcceptedRules = action.payload;
    },
  },
  extraReducers: {
    [getAccountCommonList.pending]: (state, action) => {
      // state.accountOptionList = [];
      state.accountCommonListLoading = true;
    },
    [getAccountCommonList.rejected]: (state, action) => {
      state.accountOptionList = [];
      state.accountCommonListLoading = false;
    },
    [getAccountCommonList.fulfilled]: (state, action) => {
      state.accountCommonListLoading = false;
      if (action.payload.roleName === "User") {
        state.accountOptionList = action.payload.dataArray;
      } else if (
        action.payload.roleName === "Sub Broker" ||
        action.payload.roleName === "Broker"
      ) {
        state.agentORSubBrokerList = action.payload.dataArray;
      } else if (action.payload.roleName === "Partner") {
        state.partnerList = action.payload.dataArray;
      } else if (action.payload.roleName === "Cashbook") {
        state.cashbookList = action.payload.dataArray;
      } else if (action.payload.roleName === "System User") {
        state.systemUserList = action.payload.dataArray;
      }
      state.commonAllUsers = action.payload.dataArray;
    },
    //account type
    [getAccountTypeOption.pending]: (state, action) => {
      // state.accountTypeOptionList = [];
    },
    [getAccountTypeOption.rejected]: (state, action) => {
      state.accountTypeOptionList = [];
    },
    [getAccountTypeOption.fulfilled]: (state, action) => {
      state.accountTypeOptionList = action.payload;
    },
    //segment list
    [getSegmentOptionList.pending]: (state, action) => {
      // state.segmentsList = [];
    },
    [getSegmentOptionList.rejected]: (state, action) => {
      // state.segmentsList = [];
    },
    [getSegmentOptionList.fulfilled]: (state, action) => {
      state.segmentsList = action.payload;
      state.segmentListLoading = false;
    },
    [getSegmentOptionListByUser.fulfilled]: (state, action) => {
      state.segmentsList = action.payload;
      state.segmentListLoading = false;
    },
    //script list
    [getScriptList.pending]: (state, action) => {
      state.scriptList = [];
    },
    [getScriptList.rejected]: (state, action) => {
      state.scriptList = [];
    },
    [getScriptList.fulfilled]: (state, action) => {
      state.scriptList = action.payload;
    },
    [isRulesAccepted.pending]: (state, action) => {
      state.isAcceptedRulesLoader = true
    },
    [isRulesAccepted.rejected]: (state, action) => {
      state.isAcceptedRules = false
      state.isAcceptedRulesLoader = false
    },
    [isRulesAccepted.fulfilled]: (state, action) => {
      state.isAcceptedRules = action?.payload || false
      state.isAcceptedRulesLoader = false
    },
    [getPrivacyPolicyData.pending]: (state, action) => {
      state.privacyPolicyData = [];
    },
    [getPrivacyPolicyData.rejected]: (state, action) => {
      state.privacyPolicyData = [];
    },
    [getPrivacyPolicyData.fulfilled]: (state, action) => {
      state.privacyPolicyData = action.payload;
    },

    [getSymbolList.pending]: (state, action) => {
      state.symbolList = [];
      state.symbolListLoading = true;
    },
    [getSymbolList.rejected]: (state, action) => {
      state.symbolList = [];
      state.symbolListLoading = false;
    },
    [getSymbolList.fulfilled]: (state, action) => {
      state.symbolList = action.payload;
      state.symbolListLoading = false;
    },
    [getSymbolListWithExpiry.pending]: (state, action) => {
      state.symbolListWithExpiry = [];
      state.symbolListLoading = true;
    },
    [getSymbolListWithExpiry.rejected]: (state, action) => {
      state.symbolListWithExpiry = [];
      state.symbolListLoading = false;
    },
    [getSymbolListWithExpiry.fulfilled]: (state, action) => {
      state.symbolListWithExpiry = action.payload;
      state.symbolListLoading = false;
    },
    [getSymbolListForNse.pending]: (state, action) => {
      state.symbolList = [];
      state.symbolListLoading = true;
    },
    [getSymbolListForNse.rejected]: (state, action) => {
      state.symbolList = [];
      state.symbolListLoading = false;
    },
    [getSymbolListForNse.fulfilled]: (state, action) => {
      state.symbolList = action.payload;
      state.symbolListLoading = false;
    },
    [getNotificationList.pending]: (state, action) => {
      state.notificationList = action.payload;
    },
    [getNotificationList.rejected]: (state, action) => {
      state.notificationList = action.payload;
    },
    [getNotificationList.fulfilled]: (state, action) => {
      state.notificationList = action.payload;
    },

    [getPushNotificationList.pending]: (state, action) => {
      state.pushnotificationList = [];
    },
    [getPushNotificationList.rejected]: (state, action) => {
      state.pushnotificationList = [];
    },
    [getPushNotificationList.fulfilled]: (state, action) => {
      if (Array.isArray(action.payload) && action.payload.length > 0) {
          state.pushnotificationList = action.payload;
      } else {
          state.pushnotificationList = [];
      }
    },

    //getSymbolExpiryFromSymbol
    [getSymbolExpiryFromSymbol.pending]: (state, action) => {
      state.expiryDatesBySymbolList = [];
    },
    [getSymbolExpiryFromSymbol.rejected]: (state, action) => {
      state.expiryDatesBySymbolList = [];
    },
    [getSymbolExpiryFromSymbol.fulfilled]: (state, action) => {
      state.expiryDatesBySymbolList = action.payload;
    },

    [getEditDataObjectCommon.pending]: (state, action) => { },
    [getEditDataObjectCommon.rejected]: (state, action) => {
      state.editDataObjectCommon = [];
    },
    [getEditDataObjectCommon.fulfilled]: (state, action) => {
      state.editDataObjectCommon = action.payload;
    },

    [getRightsForSession.rejected]: (state, action) => {
      state.rightsSessionList = [];
    },
    [getRightsForSession.fulfilled]: (state, action) => {
      state.rightsSessionList = action.payload;
    },
  },
});

export const {
  showMessage,
  hideMessage,
  setOpenDeleteConfirmDialog,
  setSymbolList,
  setAgentORSubBrokerList,
  setAccountOptionList,
  setIsNavbarHidden,
  setEditDataObjectCommon,
  setFocusState,
  setCommonAllUsers,
  setSymbolListWithExpiry,
  setIsAcceptedRules
} = commonSlice.actions;

export default commonSlice.reducer;
