import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import _ from "lodash";
import { showMessage } from "./common.slice";
import { getSessionData } from "utils/common";

const initialState = {
  bankDetails: {
    startAmountRange:"",
    endAmountRange:"",
    bankName: "",
    bankHolderName: "",
    bankAccountNumber: "",
    bankIFSC: "",
  },
  upiDetails: {
    bankUPI: "",
    bankUPIImage: null,
  },
  bankList:[],
  bankId:0,
  bankloading: false,
  bankTotalRecords:0,
  bankDetailsSearchText:"",
  startPageBankInRecords: 1,
  whatsAppNumber:"",
  whatsAppisActive:true,
  whatsAppId:0,
};

export const updateBankDetails = createAsyncThunk(
  "bankDetails/bankDetails/update",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      let newFinalObject = {};
      newFinalObject.id = data.bankId;
      newFinalObject.startAmountRange = data.startAmountRange;
      newFinalObject.endAmountRange = data.endAmountRange;
      newFinalObject.bankName = data.bankName;
      newFinalObject.bankHolderName = data.bankHolderName;
      newFinalObject.bankAccountNumber = data.bankAccountNumber;
      newFinalObject.bankUPIImage = data.bankUPIImage;
      newFinalObject.bankUPI = data.bankUPI;
      newFinalObject.bankIFSC = data.bankIFSC;
      newFinalObject.userId = userId;
      newFinalObject.CreatedBy = userId;
      axios
        .post("finance/bank-detail/save-bank-detail", newFinalObject)
        .then(({ data }) => {
          if (data.status === 200) {
            dispatch(showMessage({ message: "Details saved successfully !" }));
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);
export const updateWhatsappDetail = createAsyncThunk(
  "whatsappdetail/whatsappdetail/update",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      let newFinalObject = {};
      newFinalObject.id = data.whatsAppId;
      newFinalObject.whatsAppNumber = data.whatsAppNumber;
      newFinalObject.isActive = data.whatsAppisActive=="true"?true:false;
      newFinalObject.userId = userId;
      newFinalObject.createdBy = userId;
      axios
        .post("finance/whatsapp-detail/save-whatsapp-detail", newFinalObject)
        .then(({ data }) => {
          if (data.status === 200) {
            dispatch(showMessage({ message: "Details saved successfully !" }));
            resolve(data);
          } else {
            dispatch(showMessage({ message: data.message }));
            reject(data);
          }
        })
        .catch((error) => {
          if (error.response) {
            dispatch(showMessage({ message: error.response.data.message }));
          } else if (error.request) {
            dispatch(showMessage({ message: error.request.message }));
          } else {
            dispatch(showMessage({ message: error.message }));
          }
          reject(error);
        });
    });
  }
);
export const getBankDetails = createAsyncThunk(
  "bankDetails/bankDetails/get",
  ({pageNo = 1, pageSize, appendData, searchText }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      axios
        .get(
          `finance/bank-detail/get-bank-details/${userId}/${pageNo}/${pageSize}${
            searchText ? `?searchText=${searchText}` : ""
          }`
        )
        .then(({ data }) => {
          if (data.status == 200) {
            resolve({ data: data.data, appendData });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);
export const getWhatsappDetail = createAsyncThunk(
  "whatsappdetail/whatsappdetail/get",
  ({pageNo = 1, pageSize, appendData, searchText }) => {
    return new Promise((resolve, reject) => {
      let { userId } = getSessionData();
      axios
        .get(
          `finance/whatsapp-detail/get-whatsapp-details/${userId}/${pageNo}/${pageSize}${
            searchText ? `?searchText=${searchText}` : ""
          }`
        )
        .then(({ data }) => {
          if (data.status == 200) {
            resolve({ data: data.data, appendData });
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
);
export const changeBankActiveStatus = createAsyncThunk(
  "/bankDetails/statuchange",
  (data, { dispatch }) => {
    return new Promise((resolve, reject) => {
      let {id,isBankActive} = data;
      isBankActive=!isBankActive;
      axios
        .get(`finance/bank-detail/change-bank-active-status/${id}/${isBankActive}`)
        .then(({ data }) => {
         dispatch(showMessage({message: (isBankActive ? "Active" : "De-Active") + " successfully!" }));
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
)
export const bankSlice = createSlice({
  name: "bankDetails",
  initialState,
  reducers: {
    setBankList: (state, action) => {
      state.bankList = action.payload;
    },
    setBankDetailsSearchText: (state, action) => {
      state.bankDetailsSearchText = action.payload;
    },
    setStartPageBankInRecords: (state, action) => {
      state.startPageBankInRecords = action.payload;
    },
    setBankDetails: (state, action) => {
      state.bankId = action.payload.id;
      state.bankDetails={
        startAmountRange:action.payload.startAmountRange,
        endAmountRange:action.payload.endAmountRange,
        bankName: action.payload.bankName,
        bankHolderName: action.payload.bankHolderName,
        bankAccountNumber:action.payload.bankAccountNumber,
        bankIFSC:action.payload.bankIFSC,
      };
      state.upiDetails={
        bankUPI:action.payload.bankUPI,
        bankUPIImage: action.payload.bankUPIImage ? action.payload.bankUPIImage : null,
      };
    },
    setWhatsAppNumber: (state, action) => {
      state.whatsAppNumber = action.payload;
    },
    setWhatsAppStatus: (state, action) => {
      state.whatsAppisActive = action.payload;
    },
  },
  extraReducers: {
    [updateBankDetails.pending]: (state, action) => {
      state.bankloading = true;
    },
    [updateBankDetails.rejected]: (state, action) => {
      state.bankloading = false;
    },
    [updateBankDetails.fulfilled]: (state, action) => {
      state.bankloading = false;
    },
    [changeBankActiveStatus.pending]: (state, action) => {
       state.bankloading = true;
    },
    [changeBankActiveStatus.rejected]: (state, action) => {
        state.bankloading = false;
    },
    [changeBankActiveStatus.fulfilled]: (state, action) => {
        state.bankloading = false;
    },
    //get bank details
    [getBankDetails.pending]: (state, action) => {
      state.bankloading = true;
    },
    [getBankDetails.rejected]: (state, action) => {
      state.bankloading = false;
    },
    [getBankDetails.fulfilled]: (state, action) => {
      state.bankloading = false;
      if (action.payload.appendData) {
        state.bankList = [
          ...state.bankList,
          ...action.payload?.data?.records,
        ];
      } else {
        state.bankList = action.payload.data.records;
      }
      state.bankTotalRecords = action.payload.data.totalRecords;
    },

     //get bank details
    [getWhatsappDetail.pending]: (state, action) => {
      state.bankloading = true;
    },
    [getWhatsappDetail.rejected]: (state, action) => {
      state.bankloading = false;
    },
    [getWhatsappDetail.fulfilled]: (state, action) => {
      state.bankloading = false;
      if (action.payload.data.records && action.payload.data.records.length > 0) {
       const { whatsAppNumber, isActive, id } = action.payload.data.records[0];
          state.whatsAppNumber = whatsAppNumber;
          state.whatsAppisActive = isActive;
          state.whatsAppId = id;
      }
    },
  },
});

export const {setBankList,setBankDetailsSearchText,setStartPageBankInRecords,setBankDetails,bankId,setWhatsAppNumber,setWhatsAppStatus} = bankSlice.actions;

export default bankSlice.reducer;
